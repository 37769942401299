/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fullcalendar/core/main.min.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "assets/demo/flags/flags.css";


// grid jadi bagi 2
@media screen and (max-width: 960px) {
    .p-datatable-customers .p-datatable-thead > tr > th,
    .p-datatable-customers .p-datatable-tfoot > tr > td {
      display: none !important;
    }

    .p-datatable-customers .p-datatable-tbody > tr > td {
      display: flex;
      width: 100% !important;
      align-items: center;
      justify-content: space-between;
    }

    .p-datatable-customers .p-datatable-tbody > tr > td:not(:last-child) {
      border: 0 none;
    }

    .p-datatable-customers.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
      border-top: 0;
      border-right: 0;
      border-left: 0;
    }

    .p-datatable-customers .p-datatable-tbody > tr > td > .p-column-title {
      display: block;
    }
  }
  .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }
  .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
  }
  .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
  }
  .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
  }
  .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
  .p-w-100 {
    width: 100%;
  }
  .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }
  @media screen and (max-width: 960px) {
    .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td,
    .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
      display: none !important;
    }
    .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
      border-bottom: 1px solid var(--layer-2);
    }
    .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;
      border: 0;
    }
    .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
      min-width: 30%;
      display: inline-block;
      font-weight: 700;
    }
    .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td p-progressbar {
      width: 100%;
    }
    .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
      border-bottom: 1px solid var(--surface-d);
    }
  }
  @media screen and (max-width: 960px) {
    .p-datatable-customers .p-datatable-tbody > tr > td {
      display: flex;
      width: 100% !important;
      align-items: center;
      justify-content: space-between;
    }
  }

  .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    display: none;
  }
  @media screen and (max-width: 960px) {
    .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td:last-child {
      border-bottom: 1px solid #dee2e6;
    }
  }

  @media screen and (max-width: 960px) {
    .p-datatable-customers .p-datatable-tbody > tr > td > .p-column-title {
      display: block;
      min-width: 30%;
      display: inline-block;
      font-weight: 700;
      text-align: left;
    }
  }
  @media screen and (max-width: 960px) {
    .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
      min-width: 30%;
      display: inline-block;
      font-weight: 700;
    }
  }

  .instock {
    font-weight: 700;
    color: #66bb6a;
  }
  .outofstock {
    font-weight: 700;
    color: #ff5252;
  }

  .p-tag-help {
    background: #9c27b0;
    color: #ffffff;
  }
  .layout-profile-userinfo {
    text-align: left;
    display: inline-block;
    margin: 6px 0 0 8px;
    vertical-align: top;
  }
  .layout-profile-userinfo .layout-profile-name {
    font-size: 12px;
    display: block;
    // width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    color: rgba(41, 50, 65, 0.8);
    font-weight: bold;

  }
  // .p-datatable.p-datatable-customers .p-datatable-thead > tr > th.text-center {
  //    text-align: center;
  // }
  .text-center{
    text-align: center;
  }
  .layout-profile-userinfo .layout-profile-role {
    font-size: 10px;
    margin-top: 2px;
    display: block;
    opacity: 0.72;
    color: rgba(41, 50, 65, 0.8);
  }
  .icon-userinfo {
    font-size: 1.5rem;
    transition: transform 0.2s;
    display: inline-block;
    margin: 10px 0 0 8px;
    vertical-align: top;

  }
  .layout-profile-userinfo .layout-profile-rs {
    font-size: 1.3rem;
    display: block;

    font-weight: 600;
    margin-top:5px;
    overflow: hidden;
    white-space: nowrap;
    color: rgb(23, 43, 77);
  }
  .layout-profile-userinfo .layout-profile-vendor {
    font-size: 12px;
    margin-top: 2px;
    display: block;
    color: rgb(23, 43, 77);
    opacity: 0.72;
  }
  @media screen and (max-width: 960px) {
    .layout-profile-userinfo .layout-profile-rs {
      width:15rem;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  //

  .p-menu {
    // width: 100%;
  }

  .schedule > ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.schedule > ul > li {
    background: #F7F7F8;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 5px 16px 12px;
}
.schedule > ul > li .schedule-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}
.schedule > ul > li .schedule-header h6 {
  line-height: 24px;
  margin: 0;
}
.schedule > ul > li .schedule-header span {
  color: rgba(41, 50, 65, 0.5);
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}
.schedule > ul > li > span {
  margin-top: 4px;
  color: rgba(41, 50, 65, 0.5);
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.schedule > ul > li.blue > i
{
  color: #297FFF;
}
.schedule > ul > li > i {
  font-size: 8px;
  margin-right: 10px;
  margin-top: 4px;
}

.p-scrollpanel {
  p {
      padding: .5rem;
      line-height: 1.5;
      margin: 0;
  }

  & {
      .p-scrollpanel-wrapper {
          border-right: 9px solid var(--layer-1);
      }

      .p-scrollpanel-bar {
          background-color: #F7F7F8;
          opacity: 1;
          transition: background-color .2s;

          &:hover {
              background-color: #007ad9;
          }
      }
  }

  &.custombar2 {
      .p-scrollpanel-wrapper {
          border-right: 9px solid var(--layer-1);
          border-bottom: 9px solid var(--layer-1);
      }

      .p-scrollpanel-bar {
          background-color: var(--layer-2);
          border-radius: 0;
          opacity: 1;
          transition: background-color .2s;
      }
  }
}


.p-button.p-button-dark, .p-buttonset.p-button-dark > .p-button, .p-splitbutton.p-button-dark > .p-button {
  color: #FFFFFF;
  background: #64748B;
  border: 1px solid transparent;
}
.p-button.p-button-dark:enabled:hover, .p-buttonset.p-button-dark > .p-button:enabled:hover, .p-splitbutton.p-button-dark > .p-button:enabled:hover {
  background: #6C1AF2;
  color: #FFFFFF;
  border-color: transparent;
}
.p-button.p-button-dark:enabled:focus, .p-buttonset.p-button-dark > .p-button:enabled:focus, .p-splitbutton.p-button-dark > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-dark:enabled:active, .p-buttonset.p-button-dark > .p-button:enabled:active, .p-splitbutton.p-button-dark > .p-button:enabled:active {
  background: #445163;
  color: #FFFFFF;
  border-color: transparent;
}
.p-button.p-button-dark.p-button-outlined, .p-buttonset.p-button-dark > .p-button.p-button-outlined, .p-splitbutton.p-button-dark > .p-button.p-button-outlined {
  background-color: transparent;
  color: #64748B;
  border: 1px solid;
}
.p-button.p-button-dark.p-button-outlined:enabled:hover, .p-buttonset.p-button-dark > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-dark > .p-button.p-button-outlined:enabled:hover {
  background: rgba(135, 62, 254, 0.04);
  color: #64748B;
  border: 1px solid;
}
.p-button.p-button-dark.p-button-outlined:enabled:active, .p-buttonset.p-button-dark > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-dark > .p-button.p-button-outlined:enabled:active {
  background: rgba(135, 62, 254, 0.16);
  color: #64748B;
  border: 1px solid;
}
.p-button.p-button-dark.p-button-text, .p-buttonset.p-button-dark > .p-button.p-button-text, .p-splitbutton.p-button-dark > .p-button.p-button-text {
  background-color: transparent;
  color: #64748B;
  border-color: transparent;
}
.p-button.p-button-dark.p-button-text:enabled:hover, .p-buttonset.p-button-dark > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-dark > .p-button.p-button-text:enabled:hover {
  background: rgba(135, 62, 254, 0.04);
  border-color: transparent;
  color: #64748B;
}
.p-button.p-button-dark.p-button-text:enabled:active, .p-buttonset.p-button-dark > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-dark > .p-button.p-button-text:enabled:active {
  background: rgba(135, 62, 254, 0.16);
  border-color: transparent;
  color: #64748B;
}
.label-required:after {
  content: " *";
  color: #c02929;
  font-weight: 700;
}

.notification {
  padding: 30px 24px;
  background-color: #ffffff;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.notification > h6 {
  margin: 0;
  color: rgba(41, 50, 65, 0.8);
}
.notification > h6 > a {
  margin-left: 10px;
}
.notification > h6 > a i {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.label-badge {
  border-radius: 10px;
  padding: 0.25em 0.5rem;
  // text-transform: uppercase;
  // font-weight: 700;
  // font-size: 12px;
  letter-spacing: 0.3px;
}

.label-badge-success {
  border-radius: 10px;
  padding: 0.25em 0.5rem;
  letter-spacing: 0.3px;
  background: #c8e6c9;
  color: #256029;
  margin-right: 2px;
}

.label-badge-danger {
  border-radius: 10px;
  padding: 0.25em 0.5rem;
  letter-spacing: 0.3px;
  background: #ffcdd2;
  color: #c63737;
}
.label-badge.success {
  background: #c8e6c9;
  color: #256029;
  margin-right: 2px;
}
.label-badge.warn {
  background: #feedaf;
  color: #8a5340;
  margin-right: 2px;
}
.label-badge.danger {
  background: #ffcdd2;
  color: #c63737;
  margin-right: 2px;
}
.label-badge.info {
  background-color: #b3e5fc;
  color: #23547b;
  margin-right: 2px;
}
.p-menubar-root-list>.p-menuitem-active>p-menubarsub>.p-submenu-list {
    display: block;

    // position: fixed;
    position: absolute;
    position: -webkit-sticky;

}

.p-mask-ext{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #44486D;
  background: rgba(68, 72, 109, 0.07);
  padding: 0.429rem 0.571rem;
  border: 1px solid transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
}
.p-mask-ext:enabled:hover {
  border-color: transparent;
}

.p-fluid .p-mask-ext {
  width: 100%;
}
.p-mask-ext {
  margin: 0;
}
.p-mask-ext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 none;
  border-color: #EEE500;
}
.p-mask-ext:disabled {
  opacity: 0.6;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: linear-gradient(0deg, rgb(238 229 0 / 28%), rgb(238 229 0 / 13%)), #FFFFFF;
  color: #44486D;
}
.label-badge-info {
  border-radius: 10px;
  padding: 0.25em 0.5rem;
  letter-spacing: 0.3px;
  background: #d0d4ec;
  color: #7730ea;
  margin-right: 2px;
}
.label-badge-help {
  border-radius: 10px;
  padding: 0.25em 0.5rem;
  letter-spacing: 0.3px;
  background: #b0a4c4b3;
  color: #60255e;
  margin-right: 2px;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
}
.text-right {
  text-align: right!important;
}
.placeholder-content{
text-align: center;
min-height: 300px;
width: 100%;
// display: flex;
justify-content: center;
align-items: center;
padding: 0 10px;
}
 .placeholder-content img {

  display: inline-block !important;
  margin: 0 auto 12px;
}
.placeholder-content p {
  font-size: 1rem;
  font-weight: 600;
}
.p-tag.p-tag-secondary {
    background-color: #d6d6d6;
    color: #FFFFFF;
}

.label-badge-warning {
    border-radius: 10px;
    padding: 0.25em 0.5rem;
    letter-spacing: 0.3px;
    background: #EEE500;
    color: #44486D;
}

.p-tag.p-tag-danger {
    background-color: #ffcdd2 !important;
    color: #c63737;
}

.w-100{
    width: 100%;
    display: flex;
}

:root{
  --primary-x: rgb(65, 185, 131);

  --col-1: #e3f8fa;
  --col-2: #fff9dd;
  --col-3: #fac9da;
  --col-4: rgba(147, 162, 228, 0.718);
  --col-5: rgba(228, 170, 147, 0.495);

--blue-50: #f4fafe;
--blue-100: #cae6fc;
--blue-200: #a0d2fa;
--blue-300: #75bef8;
--blue-400: #4baaf5;
--blue-500: #2196f3;
--blue-600: #1c80cf;
--blue-700: #1769aa;
--blue-800: #125386;
--blue-900: #0d3c61;
--green-50: #f6fbf6;
--green-100: #d4ecd5;
--green-200: #b2ddb4;
--green-300: #90cd93;
--green-400: #6ebe71;
--green-500: #4caf50;
--green-600: #419544;
--green-700: #357b38;
--green-800: #2a602c;
--green-900: #1e4620;
--yellow-50: #fffcf5;
--yellow-100: #fef0cd;
--yellow-200: #fde4a5;
--yellow-300: #fdd87d;
--yellow-400: #fccc55;
--yellow-500: #fbc02d;
--yellow-600: #d5a326;
--yellow-700: #b08620;
--yellow-800: #8a6a19;
--yellow-900: #644d12;
--cyan-50: #f2fcfd;
--cyan-100: #c2eff5;
--cyan-200: #91e2ed;
--cyan-300: #61d5e4;
--cyan-400: #30c9dc;
--cyan-500: #00bcd4;
--cyan-600: #00a0b4;
--cyan-700: #008494;
--cyan-800: #006775;
--cyan-900: #004b55;
--pink-50: #fef4f7;
--pink-100: #fac9da;
--pink-200: #f69ebc;
--pink-300: #f1749e;
--pink-400: #ed4981;
--pink-500: #e91e63;
--pink-600: #c61a54;
--pink-700: #a31545;
--pink-800: #801136;
--pink-900: #5d0c28;
--indigo-50: #f5f6fb;
--indigo-100: #d1d5ed;
--indigo-200: #acb4df;
--indigo-300: #8893d1;
--indigo-400: #6372c3;
--indigo-500: #3f51b5;
--indigo-600: #36459a;
--indigo-700: #2c397f;
--indigo-800: #232d64;
--indigo-900: #192048;
--teal-50: #f2faf9;
--teal-100: #c2e6e2;
--teal-200: #91d2cc;
--teal-300: #61beb5;
--teal-400: #30aa9f;
--teal-500: #009688;
--teal-600: #008074;
--teal-700: #00695f;
--teal-800: #00534b;
--teal-900: #003c36;
--orange-50: #fff8f2;
--orange-100: #fde0c2;
--orange-200: #fbc791;
--orange-300: #f9ae61;
--orange-400: #f79530;
--orange-500: #f57c00;
--orange-600: #d06900;
--orange-700: #ac5700;
--orange-800: #874400;
--orange-900: #623200;
--bluegray-50: #f7f9f9;
--bluegray-100: #d9e0e3;
--bluegray-200: #bbc7cd;
--bluegray-300: #9caeb7;
--bluegray-400: #7e96a1;
--bluegray-500: #607d8b;
--bluegray-600: #526a76;
--bluegray-700: #435861;
--bluegray-800: #35454c;
--bluegray-900: #263238;
--purple-50: #faf4fb;
--purple-100: #e7cbec;
--purple-200: #d4a2dd;
--purple-300: #c279ce;
--purple-400: #af50bf;
--purple-500: #9c27b0;
--purple-600: #852196;
--purple-700: #6d1b7b;
--purple-800: #561561;
--purple-900: #3e1046;
--red-50: #fef6f5;
--red-100: #fcd2cf;
--red-200: #faaea9;
--red-300: #f88a82;
--red-400: #f6675c;
--red-500: #f44336;
--red-600: #cf392e;
--red-700: #ab2f26;
--red-800: #86251e;
--red-900: #621b16;
--primary-50: #f5f6fb;
--primary-100: #d1d5ed;
--primary-200: #acb4df;
--primary-300: #8893d1;
--primary-400: #6372c3;
--primary-500: #3f51b5;
--primary-600: #36459a;
--primary-700: #2c397f;
--primary-800: #232d64;
--primary-900: #192048;
--primary-dark-24: #FC6161;

--width: 100vw;
--full-width: 100vw;
--angle: -15deg;
--warna-head-pasien: #0BD18A;
--warna-text-pasien: white;
--magic-number: 0.09719;
--skew-padding: calc(var(--width) * var(--magic-number));
--clip-padding: calc(var(--full-width) * var(--magic-number));
--radius-rounded: 9999px;
--dark-text: hsl(226 34% 24%);
--light-text-dark-5: hsl(232 14% calc(68% - 5%));
--radius: 4px;
--white: #fff;
--danger: hsl(341 79% 53%);
--info: hsl(200 97% 45%);
--info--color-invert: rgb(255 255 255);
--primary: hsl(153 48% 49%);
--primary-light-45: hsl(153 48% calc(49% + 45%));
--primary-light-42: hsl(153 48% calc(49% + 42%));
--yellow: hsl(43 100% 72%);
--yellow-light-22: hsl(43 100% calc(72% + 22%));
--danger-light-40: hsl(341 79% calc(53% + 40%));
--fade-grey: hsl(0 0 93%);
--light-box-shadow: -1px 3px 1px 0 rgb(0 0 0 / 6%);
--primary--color-invert: rgb(255 255 255);
--fade-grey-light-2: hsl(0 0% calc(93% + 2%));
--warning: hsl( 35 95% 62%);
--radius-large: 6px;
--fade-grey-dark-3: hsl(0 0% calc(93% - 3%));
--fade-grey: hsl(0 0% 93%);
--success-light-45: hsl(164% 95% calc(43% + 45%));
// --primary-dark-24: hsl(153 48% calc(49% - 24%));
--primary-box-shadow: 0 14px 26px -12px hsl(321.51deg 48% 49% / 42%), 0 4px 23px 0 rgb(0 0 0/12%), 0 8px 10px -5px hsl(153 48% 49%/20%) !important;
--smoke-white: hsl(0 0% 99%);
--smoke-white-dark-2: hsl(0 0% calc(99% - 2%));
--muted-grey: hsl(0 0% 60%);

--success-xx: rgba(114, 231, 199, 0.435);
--secondary:hsl(222 82% 56%);
--danger:hsl(341 79% 53%);
--warning:hsl(35 95% 62%);
--purple:hsl(261 32% 55%);
--pink:hsl(344 100% 81%);
}

.r-card {
  flex: 1;
  display: inline-block;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--fade-grey-dark-3);
  transition: all 0.3s;
}

.v-avatar .avatar.is-fake.is-primary {
  background: var(--primary-light-42);
}

.v-avatar.is-small .avatar.is-fake, .v-avatar.is-small .avatar.is-more {
  width: 32px;
  min-width: 32px;
  height: 32px;
}
.v-avatar.is-small .avatar.is-fake, .v-avatar.is-small .avatar.is-more {
  width: 32px;
  min-width: 32px;
  height: 32px;
}
.v-avatar .avatar.is-fake {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--fade-grey);
  border-radius: var(--radius-rounded);
}


.tb-custom .title-ruangan {
  font-family: var(--font);
  font-size: 12px;
  color: var(--light-text);
}
.tb-custom  .title-kelas {
  font-family: var(--font);
  font-size: 12px;
  color: var(--light-text);
}
.tb-custom  .title-layan {
  font-family: var(--font);
  font-size: 14px;
  font-weight: bold;
  max-height: 42px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;

}
.tb-custom  td.center {
  vertical-align: middle;
}
.tb-custom  td.koneng {
  background-color: #fbf7e8;
  text-align: center;
  color: var(--light-text);
}

table.tb-custom {
font-family: arial, sans-serif;
border-collapse: collapse;
width: 100%;
}

.tb-custom td,
th {
padding: 8px;
}
.p-steps-item.p-disabled{
  background: white !important;

}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  background: white !important;
}
.p-knob.p-disabled{
  background: white !important;
}
.p-knob.p-disabled svg{
  background: transparent !important;
}


.timeline > ul {
  padding: 0;
  margin: 0;
  list-style: none;

  overflow: auto;
  margin-bottom: 1em;
}
.timeline > ul > li {
  display: -ms-flexbox;
  display: flex;
  background: #F7F7F8;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 5px 16px 12px;

}
.timeline > ul > li > i {
  font-size: 8px;
  margin-right: 10px;
  margin-top: 4px;
}
.timeline > ul > li .event-content span {
  display: block;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 13px;
  color: rgba(41, 50, 65, 0.5);
}
.timeline > ul > li .event-content span.event-title {
  color: #3E4754;
  font-size: 1.25rem;
}
.timeline > ul > li .event-content span.time {
  font-size: 10px;
  font-weight: 400;
  color: rgba(41, 50, 65, 0.5);
}
.timeline > ul > li.satu > i {
  color: #297FFF;
}
.timeline > ul > li.dua > i {
  color: #34B56F;
}
.timeline > ul > li.tiga > i {
  color: #FFA928;
}
.timeline > ul > li.empat > i {
  color: #8c239e;
}

.timeline > ul > li.lima > i {
  color: #c02929;
}
.timeline > ul > li.enam > i {
  color: #221ED9;
}
.timeline > ul > li.tujuh > i {
  color: #56717d;
}



.card-round-1{
    border-top-left-radius: 11px  !important;
    border-left: solid hsl( 341 79% 53%) 3px !important;
    padding-bottom: 0px  !important;
  }
  .card-round-2{
    border-top-left-radius: 11px  !important;
    border-left: solid var(--warning) 3px !important;
    padding-bottom: 0px  !important;
  }
  .card-round-3{
    border-top-left-radius: 11px  !important;
    border-left: solid var(--info) 3px !important;
    padding-bottom: 0px  !important;
  }
  .card-round-4{
    border-top-left-radius: 11px  !important;
    border-left: solid var(--purple-400) 3px !important;
    padding-bottom: 0px  !important;
  }
  .card-round-5{
    border-top-left-radius: 11px  !important;
    border-left: solid var(--pink) 3px !important;
    padding-bottom: 0px  !important;
  }
  p{
    color: #44486D;
  }
  .ck.ck-editor__editable_inline p{
    color: #44486D;
    font-weight: 600;
  }


.signature-image{
  border: 1px solid #CCC;
  border-radius: 10px;
  border-left: 4px solid #fc6160;
  cursor: crosshair;
  width: 200px;
}
.signature-img-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}